import React from 'react'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import ProductGrid from 'components/product-grid'
import Spacer from 'components/spacer'
import useResidentialZeroTurns from 'data/use-residential-zero-turns'
import { Link } from 'gatsby'
import Button from 'components/button'

const ResidentialZeroTurnsPage = () => {
  const residentialZeroTurnsData = useResidentialZeroTurns()
  return (
    <Layout
      seo={{
        title: 'Residential Zero-Turns',
        description:
          'Build and price a John Deere residential zero-turn to meet your needs. Customize your build with options, attachments, warranty, and financing and easily calculate the costs.',
      }}
    >
      <ScrollableContent>
        <h2>Residential Zero-Turns</h2>
        <Spacer size='m' />
        {residentialZeroTurnsData.length > 0 ? (
          <ProductGrid items={residentialZeroTurnsData} />
        ) : (
          <React.Fragment>
            <p>No residential zero-turns are available to build and price at this time.</p>
            <Button
              as={Link}
              to='/'
              ghost
              style={{ display: 'inline-block', textDecoration: 'none' }}
            >
              Go to homepage
            </Button>
          </React.Fragment>
        )}
      </ScrollableContent>
    </Layout>
  )
}
export default ResidentialZeroTurnsPage
